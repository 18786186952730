<template>
  <div id="app">
    <a href="#main-content" class="skip-link">Jump to main content</a>
    <site-header :open="menuOpen" @open-menu="openMenu" />
    <site-nav :open="menuOpen" @open-menu="openMenu" />
    <router-view id="main-content" />
    <site-footer />
  </div>
</template>

<script>
import SiteHeader from './components/SiteHeader.vue'
import SiteFooter from './components/SiteFooter.vue'
import SiteNav from './components/SiteNav.vue'

export default {
  name: 'App',
  components: {
    SiteHeader,
    SiteFooter,
    SiteNav
  },
  data () {
    return {
      disableScrollEffects: false,
      menuOpen: false,
      scrollEffectEls: document.querySelectorAll('.page, [data-brightness]')
    }
  },
  watch: {
    $route (to) {
      // close the menu if it's open
      this.menuOpen = false;
      document.body.classList.remove('menu-open');

      // disable during route change
      this.disableScrollEffects = true;

      // re-init the scroll effects (identify the elements that need to be affected by the scroll event)
      this.$nextTick(this.initScrollEffects);

      // scroll to the anchor if it exists
      if (to.hash) {
        this.scrollToAnchor(to.hash);
      }

      // if the route is not async, dispatch an event to let the app know it's done rendering
      if (!to.meta.async) {
        // console.log('render done')
        document.dispatchEvent(new Event('render-event'))
      }
    }
  },
  mounted () {
    this.initScrollEffects();
    document.addEventListener('scroll', this._debounce(this.handleScroll, 50));

    if (!this.$route.meta.async) {
      // console.log('render done')
      document.dispatchEvent(new Event('render-event'))
    }
  },
  methods: {
    handleScroll() {
      if (this.disableScrollEffects) return;
      // console.log('handle scroll', this.scrollEffectEls.length, this.disableScrollEffects);
      this.scrollEffectEls.forEach(($el) => {
        // determine if the element is in the viewport
        const containerOffset = $el.getBoundingClientRect().top;
        const containerHeight = $el.getBoundingClientRect().height;
        const containerBottom = containerOffset + containerHeight;
        const bodyClass = `page-color--${$el.dataset.brightness}`;
        const halfViewportHeight = window.innerHeight / 2;

        // this recolours the header based on the section (light or dark)
        if (0 < containerBottom - 42 && 0 >= containerOffset - 42) {
          // Remove all classes starting with 'page-color--'
          document.body.classList.forEach(className => {
            if (className.startsWith('page-color--')) {
              document.body.classList.remove(className);
            }
          });

          document.body.classList.add(bodyClass);
        }

        // this reveals the content when it's in the viewport
        // (half the viewport height is the trigger point)
        if (0 >= containerOffset - halfViewportHeight) {
          $el.classList.add('reveal-content');
        } else {
          $el.classList.remove('reveal-content');
        }
      });
    },
    initScrollEffects() {
      // console.log('init...', this.scrollEffectEls.length)
      document.body.classList.add('transitioning');
      this.scrollEffectEls = document.querySelectorAll('.page, [data-brightness]');

      if(this.$route.meta.banner == 'light') {
        document.body.classList.add('page-color--light');
      }

      // after the transition / smooth scroll
      // enable scroll effects
      setTimeout(() => {
        document.body.classList.remove('transitioning');
        this.disableScrollEffects = false;
        this.handleScroll();
      }, 1000);
    },
    scrollToAnchor (anchor) {
      const el = document.querySelector(anchor);
      if (el) {
        el.scrollIntoView();
      }
    },
    openMenu (state) {
      // this controls the animation of the menu
      this.menuOpen = state;

      // add a class to the body hide/show the menu
      if (this.menuOpen) {
        document.body.classList.add('menu-open');
      } else {
        // delay removing to give it time to animate
        setTimeout(() => {
          document.body.classList.remove('menu-open');
        }, 250);
      }
    }
  },
  metaInfo: {
    title: 'Octopus Ink',
    titleTemplate: '%s : Octopus Ink'
  }
}
</script>

<style lang="scss" src="./styles/app.scss"></style>
