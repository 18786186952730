<template>
  <main id="legal" class="reveal-content">
    <PageBlock class="legal" :block-columns="['Legal']" block-title="BackBtn" data-brightness="light" :grid="[1,5,11,12]" backTo="/">
      <h1>Privacy Policy</h1>
      <template v-slot:secondary>
        <p>Octopus Ink recognizes the importance of protecting the privacy of octopusink.ca web users, email newsletter subscribers, and those who attend Octopus Ink events and courses.</p>
        <h3>Business Information</h3>
        <p>Octopus Ink manages the collection of all information in strict accordance with Canada's Personal Information Protection and Electronic Documents Act (PIPEDA), and collects only regular business card information such as name, title, business address, business email and business phone number.</p>
        <h3>Personal Information</h3>
        <p>We will provide specific notice before collecting any of your personal information. We will explain what information is being collected, and how the information collected will be used. You have the right to decline the collection of this information, and its subsequent use by Octopus Ink for marketing purposes at any time.</p>
        <h3>Non-Personal Information</h3>
        <p>Our Website's operating system &mdash; like all other Websites &mdash; automatically records some general, non-personal information about your Web visit, such as:</p>
        <ul>
        <li>The domain of the Internet Service Provider (such as “company.com” or “service.ca”), and the IP address (such as “ppp-55”) of the computer used to access this site;</li>
        <li>The type of Web browser (such as “Internet Explorer version x” or “Chrome version x”), and the operating system (such as Macintosh, Unix, or Windows) used to access this site;</li>
        <li>The date and time of the visit to this Website; and,</li>
        <li>The URLs of the various webpages which are visited on this site.</li>
        <li>This tracking system does NOT record personal information about individuals.</li>
        </ul>
        <p>We use the above non-personal information for statistical analysis, and to help us make our website more useful to our visitors. We may also disclose an aggregated summary of the above information to third parties such as sponsors, advertisers and our auditors.</p>
        <p>We may also use “cookies” that identify you as a return visitor. A cookie is a piece of data that a website can send to your browser, which may then be stored on your hard drive. We might do this so that when you come back to visit us again, we can tailor information to suit your individual preferences. The goal is to save you time, and provide you with a more meaningful visit. Cookies do NOT contain ANY personally identifying information. Browsers allow you to disable cookie collection if you wish, or inform you when a cookie is being stored on your hard drive.</p>
        <h3>Email Communications</h3>
        <p>Octopus Ink uses our email newsletter to keep in touch with both our existing members, as well as individuals who have attended Octopus Ink courses or events.</p>
        <p>If at any time you decide you do not want to receive Octopus Ink's email newsletter, simply click the link at the bottom of the email newsletter to unsubscribe. We will remove you email address from our newsletter list, and will do the same with your name, title, business address and business phone number upon request.</p>
        <p>Octopus Ink does NOT sell email addresses or any other information to third parties.</p>
        <h3>Referral Links Noted On This Site</h3>
        <p>This policy discloses the privacy practices for our website. However, our website also contains links to other websites. Once you move to another site, you are subject to the privacy and security policies of the new site. We encourage you to read the privacy policies of all of the websites you visit, especially if you intend to share your personal information with that website.</p>
        <h3>Changes</h3>
        <p>Any changes to this privacy policy will be communicated promptly on this page. If you still have questions, please contact us!</p>
        <h3>Contacting Us</h3>
        <p>If there are any questions regarding this privacy policy, you may contact us using the information below.</p>
        <p>octopusink.ca<br>
        49 Spadina Ave, Suite 20<br>
        Toronto, Ontario M5V 2J1 Canada<br>
        leanne@octopusink.ca<br>
        416-977-7110</p>
        <p>Last Edited on 2016-08-17</p>
      </template>
    </PageBlock>
  </main>
</template>

<script>
import PageBlock from '@/components/PageBlock.vue'

export default {
  name: 'SingleService',
  components: {
    PageBlock
  },
  computed: {

  },
  data () {
    return {
    }
  },
  metaInfo() {
    return {
      title: 'Privacy Policy',
    }
  }
}
</script>
