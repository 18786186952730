<template>
  <div id="calc" class="marketing-calculator">
    <div class="alert alert-error" v-if="error">{{ error }}</div>

    <form class="calc-form" method="POST" action="" @submit.prevent="showResults=1; $forceUpdate()">

      <dl class="question calc-component">
        <dt><label>Is your organization publicly<span class="nb"> traded?</span></label></dt>
        <dd class="radio-list">
          <label v-for="option, index in questions.isPubliclyTraded" :key="index" class="radio-label">
            <input type="radio" name="ispublic" v-model="selected.isPubliclyTraded" :value="option === 'Yes'" /> <span>{{ option }}</span>
            </label>
        </dd>
      </dl>

      <dl class="question calc-component" :class="{ inactive: selected.isPubliclyTraded === null }">
        <dt><label>What type of business are<span class="nb"> you in?</span></label></dt>
        <dd class="radio-list">
          <label v-for="(option, key) in questions.typeOfBusiness" :key="key" class="radio-label">
            <input type="radio" name="typeOfBusiness" :disabled="selected.isPubliclyTraded === null" v-model="selected.typeOfBusiness" :value="key" /> <span>{{ option }}</span>
            </label>
        </dd>
      </dl>

      <dl class="question calc-component" :class="{ inactive: selected.typeOfBusiness === null }">
        <dt><label>What industry<span class="nb"> are you in?</span></label></dt>
        <dd>
          <v-select :options="questions.industry" :disabled="selected.typeOfBusiness === null"
            placeholder="Choose One"
            v-model="selected.industry"></v-select>
        </dd>
      </dl>

      <dl class="question calc-component" :class="{ inactive: selected.industry === null }">
        <dt><label for="annualSales">What are your desired annual<span class="nb"> sales?</span></label></dt>
        <dd>
          <div class="text-input">
            <money v-model="selected.annualSales"
              prefix="$"
              :disabled="selected.industry === null" onclick="this.select()"></money>
          </div>

          <button type="submit" class="btn btn-calculate" :disabled="selected.annualSales === 0" >Calculate</button>
        </dd>
      </dl>
    </form>

    <div class="reveal-section" ref="reveal-section">
      <div class="calc-component">
        <dl class="result" id="output-container">
          <dt><label for="output">Suggested marketing spend</label></dt>
          <dd>
            <div :class="{ filled: results, loading: !results }" class="output">
              <output id="output">${{ results.min }} <span class="sep">to</span> ${{ results.max }}</output>
              <div class="spin-doctor"></div>
            </div>
          </dd>
        </dl>

        <div class="calc-component-footer">
          <h3>Want to grow your business?</h3>
          <p>If you'd like to learn more about how to build a marketing plan that delivers a real ROI, you can reach Leanne at:</p>
          <p><strong><a href="tel:+14169777110">416.977.7110</a></strong> or <a href="mailto:leanne@octopusink.ca">leanne@octopusink.ca</a></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { VueSelect } from "vue-select";
import {Money} from 'v-money';

Number.prototype.formatMoney = function(c, d, t){
  var n = this,
  c2 = isNaN(c = Math.abs(c)) ? 2 : c,
  d2 = d == undefined ? "." : d,
  t2 = t == undefined ? "," : t,
  s = n < 0 ? "-" : "",
  i = String(parseInt(n = Math.abs(Number(n) || 0).toFixed(c2))),
  j = (j = i.length) > 3 ? j % 3 : 0;
  return s + (j ? i.substr(0, j) + t2 : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t2) + (c2 ? d2 + Math.abs(n - i).toFixed(c2).slice(2) : "");
};

export default {
  name: 'MarketingCalculator',
  components: {
    vSelect: VueSelect,
    Money
  },
  mounted() {
    const vm = this;
    axios.get(
      '/api/industries'
    ).then(resp => {
      vm.questions.industry = resp.data;
    }).catch(this.calculatorApiError);
  },
  watch: {
    error() {
      const $el = document.getElementById('calc');
      if ($el) {
        $el.scrollIntoView({ behavior: 'smooth' })
      }
    },
    selected: {
      handler() {
        this.showResults = 0;
        this.error = null;
      },
      deep: true
    },
    showResults(to) {
      if (to) {
        this.results = false;
        this.calculate();
      } else {
        if (this.$refs['reveal-section']) {
          this.$refs['reveal-section'].style.height = 0;
          this.$refs['reveal-section'].classList.remove('reveal');
        }
      }
    }
  },
  methods: {
    calculatorApiError(reason) {
      if (reason.message === 'Network Error' || (reason.response && reason.response.status === 404)) {
        this.error = 'Error: Calculator is Offline';
      } else if (reason.response && reason.response.status === 500) {
        this.error = 'Error: Calculator is experiencing difficulties';
      }
      this.error = 'Error: ' + reason.message;
      this.$forceUpdate();
    },
    calculate() {
      const vm = this;
      vm.error = null;
      this._each(vm.selected, (selected, field) => {
        if (selected === 0 || selected === null && vm.error) {
          vm.error = `Please fill in required field: ${this._startCase(field)}`;
        }
      });

      if (!vm.error) {
        const $reveal = this.$refs['reveal-section'];
        if ($reveal) {
          $reveal.classList.add('reveal');
          $reveal.style.height = $reveal.children[0].clientHeight + 'px';
          setTimeout(() => {
            $reveal.scrollIntoView({ behavior: 'smooth' })
          }, 1000);
        }
        const sales = vm.selected.annualSales;

        axios.post('/api/calculator', {
          public: vm.selected.isPubliclyTraded,
          type: vm.selected.typeOfBusiness,
          industry: vm.selected.industry,
          sales
        }).then(resp => {
          console.log(resp.data);
          setTimeout(() => {
            vm.results = {
              min: (sales * (this._toNumber(resp.data.min_budget) / 100)).formatMoney(2, '.', ','),
              max: (sales * (this._toNumber(resp.data.max_budget) / 100)).formatMoney(2, '.', ',')
            };
          }, 1000);

          // TODO: analytics event
        }).catch(this.calculatorApiError);
      }
    }
  },
  data () {
    return {
      error: null,
      showResults: 0,
      results: false,
      questions: {
        isPubliclyTraded: [
          'Yes',
          'No'
        ],
        typeOfBusiness: {
          b2b: "Business to Business",
          b2c: "Business to Customer",
          both: "Both"
        },
        industry: []
      },
      selected: {
        isPubliclyTraded: null,
        typeOfBusiness: null,
        industry: null,
        annualSales: 0
      }
    }
  }
}
</script>
