<template>
  <main id="service">
    <kinesis-container class="page page--subpage page--hero" style="background-color: #3a3584" data-brightness="dark" event="scroll">
      <kinesis-element :strength="-100" axis="y" class="hero__image">
        <img :src="serviceHero" :alt="service.title">
      </kinesis-element>
      <div class="wrapper">
        <div class="hero__content">
          <h1>
            {{ service.title }}
          </h1>
        </div>
      </div>
    </kinesis-container>

    <PageBlock class="service" block-title="BackBtn" :block-columns="['Services']" :block-columns-link="['/services']" data-brightness="light" :grid="[1,5,11,12]" :backTo="backTo">
      <h2>{{ service.subtitle || service.title }}</h2>
      <template v-slot:secondary>
        <div class="anim">
          <div v-html="service.content" class="body-content"></div>

          <div class="lists" v-for="(list, index) in service.links" :key="index">
            <h4>{{ list.title }}</h4>
            <ul>
              <li v-for="(item, index) in list.list" :key="index">
                <router-link :to="{ name: 'our-work', params: { slug: item } }" class="text-link">
                  {{ work[item].title }}
                </router-link>
              </li>
            </ul>
          </div>
        </div>
        <div class="anim anim2">
          <img :src="require(`../assets/services/service-${service.slug}.png`)" :alt="service.title" />
        </div>
        <div class="anim">

          <h3>
            Want to learn more about strategy?
          </h3>

          <p>
            Let's talk. Call <a href="tel:+14169777110" class="text-link">416-977-7110</a> or email Leanne at <a href="mailto:leanne@octopusink.ca" class="text-link">leanne@octopusink.ca</a>
          </p>
        </div>
      </template>
    </PageBlock>
  </main>
</template>

<script>
import services from '@/assets/services.yaml';
import work from '@/assets/work.yaml';
import PageBlock from '@/components/PageBlock.vue'

export default {
  name: 'SingleService',
  components: {
    PageBlock
  },
  mounted () {
    if (!this.services[this.$route.params.slug]) {
      this.$router.push('/404');
      return
    }
  },
  computed: {
    service () {
      const service = this.services[this.$route.params.slug]
      service.slug = this.$route.params.slug;

      if (service.links) {
        service.links.filter((list) => {
          list.list = list.list.filter((item) => {
            return this.work[item] && this.work[item].visibility;
          });
        });
      }

      return service;
    },
    backTo() {
      return '/#about-us';
    },
    serviceHero() {
      if (this.service.hero) {
        return require(`../assets/heros/${this.service.hero}`);
      } else {
        return require('../assets/heros/slide-4-suctions.webp')
      }
    }
  },
  data () {
    return {
      services,
      work
    }
  },
  metaInfo() {
    return {
      title: this.service.seo.title,
      meta: [
        { name: 'description', content: this.service.seo.description },
        { property: 'og:title', content: `${this.service.seo.title} : Octopus Ink` },
        { property: 'og:site_name', content: 'Octopus Ink' },
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: `https://octopusink.ca/services/${this.service.slug}` },
        { property: 'og:image', content: 'https://octopusink.ca' + require(`../assets/services/service-${this.service.slug}.png`) },
        { property: 'og:description', content: this.service.seo.description },

        { name: 'twitter:card', content: 'summary' },
        { name: 'twitter:site', content: `https://octopusink.ca/services/${this.service.slug}` },
        { name: 'twitter:title', content: `${this.service.seo.title} : Octopus Ink` },
        { name: 'twitter:description', content: this.service.seo.description },

        { name: 'twitter:image:src', content: 'https://octopusink.ca' + require(`../assets/services/service-${this.service.slug}.png`) },

        { itemprop: 'name', content: `${this.service.seo.title} : Octopus Ink` },
        { itemprop: 'description', content: this.service.seo.description },
        { itemprop: 'image', content: 'https://octopusink.ca' + require(`../assets/services/service-${this.service.slug}.png`) }
      ],
      link: [
        { rel: 'canonical', href: `https://octopusink.ca/services/${this.service.slug}` }
      ]
    }
  }
}
</script>
