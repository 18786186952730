<template>
  <main id="marketing-calculator">
    <kinesis-container event="scroll">
      <PageBlock class="page--content__dark marketing-calculator--hero" data-brightness="light" :grid="[]">
        <kinesis-element :strength="-100" axis="y" class="hero__image">
          <img src="@/assets/heros/hero-calculator.png" alt="Let me crunch your numbers" />
        </kinesis-element>
        <template v-slot:secondary>
          <kinesis-element :strength="100" axis="y" class="hero__content">
            <h1>
              Marketing Spend Calculator
            </h1>

            <blockquote>
              Doing business without advertising is like winking at a girl in the dark. You know what you are doing, but nobody else does.

              <cite>Steuart Henderson Britt, US author</cite>
            </blockquote>
          </kinesis-element>
        </template>
      </PageBlock>
    </kinesis-container>

    <PageBlock class="marketing-calculator--page" :block-columns="['Marketing Spend Calculator']" block-title="BackBtn" data-brightness="light" :grid="[1,5,11,12]" backTo="/">
      <h2>How much should you spend?</h2>
      <template v-slot:secondary>
        <div class="anim">
          <p>
            Most companies are great at setting goals and objectives. What is often more difficult is figuring out what do you need to spend on marketing to achieve those goals and objectives.
          </p>

          <p>
            So, we came up with a tool to help our clients understand what businesses spend on marketing based on industry averages. The calculations are developed from researching publicly and privately held companies, financial statements, and interviewing a vast array of marketing professionals. It's by no means a hard and fast rule &mdash; but we find it helps our clients understand what their annual investment should be.
          </p>

          <MarketingCalculator />
        </div>
      </template>
    </PageBlock>
  </main>
</template>

<script>
import PageBlock from '@/components/PageBlock.vue'
import MarketingCalculator from '@/components/MarketingCalculator.vue'

export default {
  name: 'SingleService',
  components: {
    PageBlock,
    MarketingCalculator
  },
  computed: {

  },
  data () {
    return {
    }
  },
  metaInfo() {
    return {
      title: 'Marketing Spend Calculator',
      meta: [
        { name: 'description', content: 'Use our calculator to see what businesses like yours are spending on marketing.' },
        { property: 'og:title', content: `Marketing Spend Calculator : Octopus Ink` },
        { property: 'og:site_name', content: 'Octopus Ink' },
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: `https://octopusink.ca/marketing-spend-calculator` },
        // { property: 'og:image', content: 'https://octopusink.ca' + require(`../assets/marketing-spend-calculator/service-${this.service.slug}.png`) },
        { property: 'og:description', content: 'Use our calculator to see what businesses like yours are spending on marketing.' },

        { name: 'twitter:card', content: 'summary' },
        { name: 'twitter:site', content: `https://octopusink.ca/marketing-spend-calculator` },
        { name: 'twitter:title', content: `Marketing Spend Calculator : Octopus Ink` },
        { name: 'twitter:description', content: 'Use our calculator to see what businesses like yours are spending on marketing.' },

        // { name: 'twitter:image:src', content: 'https://octopusink.ca' + require(`../assets/marketing-spend-calculator/service-${this.service.slug}.png`) },

        { itemprop: 'name', content: `Marketing Spend Calculator : Octopus Ink` },
        { itemprop: 'description', content: 'Use our calculator to see what businesses like yours are spending on marketing.' },
        // { itemprop: 'image', content: 'https://octopusink.ca' + require(`../assets/marketing-spend-calculator/service-${this.service.slug}.png`) }
      ],
      link: [
        { rel: 'canonical', href: `https://octopusink.ca/marketing-spend-calculator` }
      ]
    }
  }
}
</script>
