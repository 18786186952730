<template>
    <div class='pagination-container' :class='props.theme.wrapper'>
        <nav>

            <span v-if="props.hasEdgeNav" :class='props.theme.firstPage' @click="props.setFirstPage">
                <a v-bind="{...props.aProps,...props.firstPageProps}">{{props.texts.first}}</a>
            </span>

            <span v-if="props.hasChunksNav" :class='props.theme.prevChunk' @click="props.setPrevChunk">
                <a v-bind="{...props.aProps, ...props.prevChunkProps}">{{props.texts.prevChunk}}</a>
            </span>

            <span class="previous-posts-link" @click="props.setPrevPage">
                <a v-bind="{...props.aProps,...props.prevProps}">{{props.texts.prevPage}}</a>
            </span>

            <ul v-show="props.showPagination" :class="props.theme.list" class='pagination'>

                <li v-for="page in props.pages" :key="page" :class="props.pageClasses(page)" class="page-numbers"
                    v-on="props.pageEvents(page)">
                    <a v-bind="props.aProps" :class="props.theme.link">{{padded(page)}}</a>
                </li>

            </ul>

            <span class="next-posts-link" @click="props.setNextPage">
                <a v-bind="{...props.aProps, ...props.nextProps}">{{props.texts.nextPage}}</a>
            </span>

            <span v-if="props.hasChunksNav" :class='props.theme.nextChunk' @click="props.setNextChunk">
                <a v-bind="{...props.aProps, ...props.nextChunkProps}">{{props.texts.nextChunk}}</a>
            </span>

            <span v-if="props.hasEdgeNav" :class="props.theme.lastPage" @click="props.setLastPage">
                <a v-bind="{...props.aProps, ...props.lastPageProps}">{{props.texts.last}}</a>
            </span>

            <p v-show="props.hasRecords" :class='props.theme.count'>{{props.count}}</p>

        </nav>
    </div>

</template>

<script>
    export default {
        name: 'MyPagination',
        props: ['props'],
        methods: {
            padded(page) {
                return this.props.opts.padded ? this.pad(page) : page;
            },
            pad(page) {
                return (page < 10) ? '0' + page : page;
            }
        }
    }
</script>
