<template>
  <footer class="contentinfo page page--content page--content__very-dark"  data-brightness="dark">
    <div class="wrapper">
      <router-link to="/" class="tagline" aria-label="Octopus Ink: Leave Your Mark">
        <inline-svg :src="require('../assets/tagline-octopusink.svg')" class="brand" fill="#fff"/>
      </router-link>

      <div class="links">
        <nav class="socials">
          <ul>
            <li v-for="social in socials" :key="social.name">
              <a :href="social.url" target="_blank">
                  <button class="button button--social" role="button" :aria-label="`Octopus Ink on ${social.name}`" tabindex="-1">
                    <FontAwesomeIcon :icon="social.icon" />
                  </button>
              </a>
            </li>
          </ul>
        </nav>

        <a href="https://iabcanada.com/" target="_blank">
          <img :src="require('@/assets/iabmember-badge.png')" alt="IAB Member" class="iab-member-badge" />
        </a>
      </div>
    </div>

    <div class="copyright">
      <div class="wrapper">
        <p>{{ year }} &copy; Octopus Ink. All rights reserved.</p>

        <nav class="legal-links">
          <ul>
            <li><router-link to="/privacy-policy">Privacy Policy</router-link></li>
          </ul>
        </nav>
      </div>
    </div>
  </footer>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faInstagram, faFacebookF, faLinkedinIn } from '@fortawesome/free-brands-svg-icons'

export default {
  name: 'SiteFooter',
  components: {
    FontAwesomeIcon
  },
  data () {
    return {
      year: new Date().getFullYear(),
      socials: [
        {
          name: 'Instagram',
          url: 'https://www.instagram.com/octopusinkstagram/',
          icon: faInstagram
        },
        {
          name: 'Facebook',
          url: 'https://www.facebook.com/OctopusInkTO',
          icon: faFacebookF
        },
        {
          name: 'LinkedIn',
          url: 'https://www.linkedin.com/company/2579813',
          icon: faLinkedinIn
        }
      ]
    }
  }
}
</script>
