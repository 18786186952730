<template>
  <div :style="{background: item.accent}">
    <router-link :to="{ name: 'our-work', params: { slug: item.slug } }">
      <img :src="require(`../assets/work/main-${item.slug}.jpg`)" :alt="item.title" />
      <div class="overlay">
        <div class="overlay__inner">
          <h3>{{ item.title }}</h3>
          <p>{{ item.service }}</p>

          <button class="button button--outline" role="button" :aria-label="`Open ${item.title} Case Study`" tabindex="-1">
            View
          </button>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'CaseStudyThumbnail',
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  }
}
</script>
