<template>
  <main id="blog-post" class="reveal-content">
    <kinesis-container class="page page--subpage page--hero page--hero--article" data-brightness="dark" event="scroll" :class="{'page--hero--article__loading': !post, 'page--hero--article__small': post && !post.custom_fields.large_image_hero}">
      <kinesis-element :strength="100" axis="y" class="hero__image" v-if="post && post.custom_fields.large_image_hero">
        <responsive-image :prefetchedImage="post.post_thumbnail" :alt="post.title.rendered" />
      </kinesis-element>
      <div class="wrapper">
        <kinesis-element :strength="-100" axis="y" class="hero__content">
          <h1 v-if="post">
            {{ post.title.rendered }}
          </h1>
          <h1 v-else>
            ███████████████ ████
          </h1>
          <PostMeta :post="post" v-if="post" />
          <p class="meta" v-else>██████, ███████ - ██ █████ ████████ - ███ ███ ██ ████</p>
        </kinesis-element>
        <kinesis-element :strength="100" axis="y" class="hero__image" v-if="post && !post.custom_fields.large_image_hero">
          <responsive-image :prefetchedImage="post.post_thumbnail" :alt="post.title.rendered" />
        </kinesis-element>
      </div>
    </kinesis-container>


    <div class="post" data-brightness="light">
      <GridLines :columns="[2,10,12]" />
      <div class="wrapper">
        <div class="page--backbtn">
          <div class="horizontal-text">
            <router-link to="/blog" class="back-button">
              Back
            </router-link>
          </div>
        </div>
        <div v-if="post" v-html="post.content.rendered" class="post--content"></div>
        <div v-else class="post--content alert">Loading post contents...</div>
        <div class="page--social-share">
          <social-share :post="post" v-if="post" />
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import GridLines from '@/components/GridLines.vue'
import PostMeta from '@/components/PostMeta.vue'
import SocialShare from '@/components/SocialShare.vue'

export default {
  name: 'BlogPost',
  components: {
    GridLines,
    PostMeta,
    SocialShare
  },
  mounted () {
    this.$store.dispatch('getCategories').then(() => {
      this.$store.dispatch('getAuthors').then(() => {
        this.getPost()
      }).catch(err => {
        this.error = err
      });
    });
  },
  computed: {
    serverErr() {
      return this.$store.state.error
    }
  },
  data () {
    return {
      loading: true,
      error: null,
      post: null
    }
  },
  methods: {
    getPost () {
      if (!this.$route.params.slug) return null

      this.$store.dispatch('getPost', {
        slug: this.$route.params.slug
      }).then(() => {
        this.loading = false
        this.$nextTick(() => {
          // console.log('render done')
          document.dispatchEvent(new Event('render-event'))
        })
        this.post = this.$store.getters.thisPost(this.$route.params.slug)
        if (this.post.yoast_head) {
          const yoastHeadStart = '<!-- This site is optimized with the Yoast SEO plugin';
          const yoastHeadEnd = '<!-- / Yoast SEO plugin. -->';
          const headContent = document.head.innerHTML;
          const startIndex = headContent.indexOf(yoastHeadStart);
          if (startIndex > -1) {
            const endIndex = headContent.indexOf(yoastHeadEnd) + yoastHeadEnd.length;
            const newHeadContent = headContent.substring(0, startIndex) + headContent.substring(endIndex);
            document.head.innerHTML = newHeadContent;
          }
      if (document.head.querySelector('title')) {
        document.head.querySelector('title').remove()
      }
          document.head.innerHTML = this.post.yoast_head + document.head.innerHTML
          }
        if (this.$store.state.error) {
          if (this.$store.state.error === 'No post found') {
            this.$router.push('/404')
          }
        }
      }).catch(err => {
        console.log(err)
        this.$nextTick(() => {
          console.log('render done with error')
          document.dispatchEvent(new Event('render-event'))
        })
        this.error = err
      });
    }
  }
}
</script>
