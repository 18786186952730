<template>
  <p class="meta">
    <span :key="index" v-for="cat, index in listCategories(post.categories)">
      <router-link :to="{ name: 'blog-category', params: { category: cat.slug }}" class="text-link">{{ cat.name }}</router-link><span v-if="index != Object.keys(listCategories(post.categories)).length - 1">, </span>
    </span>
  -
    <span v-if="author">
      By <router-link :to="{ name: 'blog-author', params: { author: author.slug } }" class="text-link">{{ author.name }}</router-link>
    </span>
    -
    {{ post.date | moment('MMMM D, YYYY') }}
  </p>
</template>

<script>
export default {
  props: {
    post: {
      type: Object,
      required: true
    }
  },
  computed: {
    author() {
      return this.$store.getters.thisAuthorById(this.post.author)
    }
  },
  methods: {
    listCategories (categories) {
      categories = categories.filter(function( cat ) {
        return cat !== 1 // "uncategorized"
      })
      return this.$store.getters.theseCategories(categories)
    }
  }
}
</script>
