<template>
  <main id="homepage">
    <kinesis-container class="page page--hero" style="background-color: #8B608D" data-brightness="dark" event="scroll">
      <kinesis-element :strength="-50" axis="y" class="hero__image">
        <responsive-image name="slide-1-heart" alt="Leave Your Mark" />
      </kinesis-element>
      <div class="wrapper">
        <kinesis-element :strength="100" axis="y" class="hero__content">
          <h2>
            <span class="nb">Feel the&nbsp;</span>
            <span class="nb">Love.</span>
          </h2>
          <p>
            As a full-service boutique advertising agency, we guarantee senior level strategic insight from brief to execution.
          </p>
        </kinesis-element>
      </div>
    </kinesis-container>
    <WorkBlock id="our-work" class="work" block-title="Work" :block-columns="['Our Work', 'Case Studies']">
      <h2>
        We believe that our work needs to drive your sales.
      </h2>
      <p>
        We're nothing without the amazing partnerships we have with our clients. Please take a scroll through just a few examples of the work we've done to drive leads, trial, clicks, traffic and conversions.
      </p>
    </WorkBlock>
    <AboutUsBlock id="about-us"  class="page--content__dark about-us" block-title="About Us" :block-columns="['Profile', 'Services']" data-brightness="light">
      <h2>
        What we do.
      </h2>
      <p>
        We do a lot. We have to. Because the success of your marketing efforts depends on your trust in us to provide a fully integrated plan and executional excellence so you can enjoy the fruits of your labour. Plus - what we do is a lot of fun and we strive to make sure every client we work with enjoys the ride.
      </p>
      <router-link :to="{ name: 'services' }" role="button" aria-label="Open All Services">
        <button class="button" tabindex="-1">
          View Services
        </button>
      </router-link>

      <template v-slot:secondary>
        <h2>
          We do a lot.
        </h2>
      </template>
    </AboutUsBlock>
    <div id="the-team">
      <kinesis-container class="page page--hero" style="background-color: #000213" data-brightness="dark" event="scroll">
        <kinesis-element :strength="-50" axis="y" class="hero__image">
          <responsive-image name="slide-2-eye" alt="There's no 'I' in Octopus." />
        </kinesis-element>
        <div class="wrapper">
          <kinesis-element :strength="100" axis="y" class="hero__content">
            <h2>
              <span class="nb">There's no “I”</span> <span class="nb">in Octopus.</span>
            </h2>
            <p>
              We're proud of the teamwork, passion and expertise our team brings to the table.
            </p>
          </kinesis-element>
        </div>
      </kinesis-container>
      <TeamBlock class="team" block-title="The Team" :block-columns="['Who we are', 'The Team']" data-brightness="light">
        <h2>
          We're a team of like-minded people who work hard and have fun.
        </h2>
        <p>
          As a boutique advertising agency - culture, collaboration and common sense is key to keeping us nimble and sharp. Every member of our team shares the same core values of respect, family, trust, integrity and open-mindedness. And while we are each very unique individuals with different ideas and insights, we have a lot of fun together and it shows in our performance and attitude.
        </p>
      </TeamBlock>
    </div>
    <div  id="marketing-calculator">
      <kinesis-container class="page page--hero" style="background-color: #211925" data-brightness="dark" event="scroll">
        <kinesis-element :strength="-50" axis="y" class="hero__image">
          <responsive-image name="slide-3-shells" alt="How much should you spend" />
        </kinesis-element>
        <div class="wrapper">
          <kinesis-element :strength="200" axis="y" class="hero__content">
            <h2>
              <span class="nb">How much should </span>
              <span class="nb">you spend?</span>
            </h2>
            <p>
              Check out our calculator to discover the average annual marketing budgets for your industry.
            </p>
          </kinesis-element>
        </div>
      </kinesis-container>
      <kinesis-container class="big-image--container" event="scroll">
        <PageBlock class="page--content__dark marketing-budget-calculator" block-title="Calculator" :block-columns="['Budget']" data-brightness="light">
          <h2>
            Figure out your budget
          </h2>
          <p>
            Use our calculator to see what businesses like yours are spending on marketing.
          </p>
          <router-link :to="{ name: 'marketing-spend-calculator' }" role="button" aria-label="Open Marketing Spend Calculator">
            <button class="button" tabindex="-1">
              View Calculator
            </button>
          </router-link>
          <template v-slot:secondary>
            <div class="big-image">
              <kinesis-element :strength="200" axis="y" class="big-image__image">
                <img :src="require('../assets/calculator-octopus.png')" alt=""/>
              </kinesis-element>
            </div>
          </template>
        </PageBlock>
      </kinesis-container>
    </div>
    <kinesis-container class="big-image--container" event="scroll">
      <PageBlock id="media" class="media" block-title="Media" :block-columns="['Impact']" data-brightness="light">
        <h2>
          Media that works.<br />
          Extend your reach.
        </h2>
        <p>
          As an independent, privately-held digital media agency, Octopus Ink has the flexibility and latitude to offer our clients the right media solution to meet their specific objectives.
        </p>
        <router-link :to="'/services/media-planning'" role="button" aria-label="About Media">
          <button class="button" tabindex="-1">
            Media Expertise
          </button>
        </router-link>
        <template v-slot:secondary>
          <div class="big-image">
            <kinesis-element :strength="-200" axis="y" class="big-image__image">
              <img :src="require('../assets/media-tentacle.png')" alt=""/>
            </kinesis-element>
          </div>
        </template>
      </PageBlock>
    </kinesis-container>
    <kinesis-container class="page page--hero" style="background-color: #070c1f" data-brightness="dark" event="scroll">
      <kinesis-element :strength="-50" axis="y" class="hero__image">
        <responsive-image name="slide-5-mouth" alt="Put your money where your mouth is" />
      </kinesis-element>
      <div class="wrapper">
        <kinesis-element :strength="100" axis="y" class="hero__content">
          <h2>
            <span class="nb">Put your money </span>
            <span class="nb">where your mouth is.</span>
          </h2>
          <p>
            Our reporting protocol ensures you know what's working and what needs to be fixed.
          </p>
        </kinesis-element>
      </div>
    </kinesis-container>
    <PageBlock id="reporting" class="page--content__dark reporting" block-title="Reporting" :block-columns="['Our Tools']" data-brightness="light">
      <h2>
        Reporting with insight.
      </h2>
      <p>
        Data and reporting are meaningless if there isn't an insight and a go-forward strategy attached to them. That's why we developed an online dashboard for our clients to login and view their data and performance at a glance.
      </p>
      <a href="#contact" role="button" aria-label="Contact us">
        <button class="button" tabindex="-1">
          Contact us for a demo
        </button>
      </a>
      <template v-slot:secondary>
        <div class="homepage-slider reporting-slider" v-if="currentReportingSlide">
          <div class="homepage-slider--info-column reporting-slider--about anim">
            <p class="column-label">
              Web Intelligence
            </p>

            <h3>
              {{ currentReportingSlide.title }}
            </h3>

            <p>
              {{ currentReportingSlide.description }}
            </p>
          </div>
          <div class="homepage-slider--images reporting-slider--images">
            <div class="homepage-slider--images__container">
              <div class="homepage-slider--images__container__inner reporting-slider--images__container__inner" :style="{ transform: 'translateY(-' + ((reportingSliderIndex / reportingSlider.length) * 100) + '%)' }" role="group">
                <div class="homepage-slider--images__container__inner__slide reporting-slider--images__container__inner__slide" v-for="(slide, index) in reportingSlider" :key="index" :class="{current: index == reportingSliderIndex}" :style="{background: slide.accent}"  :aria-hidden="index != reportingSliderIndex" role="region" :aria-label="slide.title">
                  <img :src="currentReportingSlide.image" :alt="`${slide.title} ${slide.description}`" />
                </div>
              </div>
            </div>
            <button class="homepage-slider--controls reporting-slider--controls homepage-slider--controls__up reporting-slider--controls__up" @click="reportingSliderIndex = reportingSliderIndex - 1" :disabled="reportingSliderIndex == 0" aria-label="Previous Slide" :aria-disabled="reportingSliderIndex == 0">
              <span class="icon icon--arrow-up"></span>
            </button>
            <button class="homepage-slider--controls reporting-slider--controls homepage-slider--controls__down reporting-slider--controls__down" @click="reportingSliderIndex = reportingSliderIndex + 1" :disabled="reportingSliderIndex == reportingSlider.length - 1" aria-label="Next Slide" :aria-disabled="reportingSliderIndex == reportingSlider.length - 1">
              <span class="icon icon--arrow-down"></span>
            </button>
          </div>
        </div>
      </template>
    </PageBlock>
    <ContactBlock id="contact" class="page--content__very-dark contact" block-title="Contact" :block-columns="['Contact Us', 'Get in Touch']" data-brightness="dark" address="49 Spadina Avenue,<br />Suite 203<br />Toronto, ON &nbsp; M5V&nbsp;2J1" directions="https://goo.gl/maps/8uTUMXHPXZm">
      <h2>
        Everything starts with a conversation. And we'd love to chat with you.
      </h2>
      <ul class="contact--methods">
        <li>
          <dl>
            <dt>
              Let's Talk
            </dt>
            <dd>
              T: <a href="tel:+14169777110" class="text-link">416.977.7110</a>
            </dd>
          </dl>
        </li>
        <li>
          <dl>
            <dt>
              Or email
            </dt>
            <dd>
              <a href="mailto:leanne@octopusink.ca" class="text-link">leanne@octopusink.ca</a>
            </dd>
          </dl>
        </li>
        <li>
          <dl>
            <dt>
              Octopus Ink
            </dt>
            <dd>
              <address>
                49 Spadina Avenue,<br />
                Suite 203<br />
                Toronto, ON &nbsp; M5V&nbsp;2J1
              </address>
              <a target="_blank" href="https://goo.gl/maps/8uTUMXHPXZm" class="text-link">
                Get Directions</a>
            </dd>
          </dl>
        </li>
      </ul>
      <template v-slot:info-window>
        <div class="info-window">
          <inline-svg :src="require('@/assets/logo-octopusink.svg')" class="brand" fill="#070707"/>
          <address>
            49 Spadina Avenue,<br />
            Suite 203<br />
            Toronto, ON &nbsp; M5V&nbsp;2J1
          </address>
          <a target="_blank" href="https://goo.gl/maps/8uTUMXHPXZm" class="text-link">
            Get Directions
          </a>
        </div>
      </template>
    </ContactBlock>
  </main>
</template>

<script>
import WorkBlock from '@/components/WorkBlock.vue'
import AboutUsBlock from '@/components/AboutUsBlock.vue'
import TeamBlock from '@/components/TeamBlock.vue'
import PageBlock from '@/components/PageBlock.vue'
import ContactBlock from '@/components/ContactBlock.vue'

export default {
  name: 'HomePage',
  components: {
    WorkBlock,
    AboutUsBlock,
    TeamBlock,
    ContactBlock,
    PageBlock
  },
  computed: {
    currentReportingSlide () {
      return this.reportingSlider[this.reportingSliderIndex]
    }
  },
  data () {
    return {
      reportingSliderIndex: 0,
      reportingSlider: [
        {
          title: 'KPI Dashboard',
          description: 'Get your site\'s key data, all in one spot.',
          image: require('../assets/reporting-dashboard.jpg')
        },
        {
          title: 'See Trends',
          description: 'Follow performance month-over-month to gain big picture insights.',
          image: require('../assets/reporting-trends.jpg')
        },
        {
          title: 'Insight Reporting',
          description: 'Learn what\'s happening and why, with recommended actions.',
          image: require('../assets/reporting-monthly.jpg')
        }
      ]
    }
  },
  metaInfo: {
    title: 'Octopus Ink : Leave Your Mark',
    meta: [
      { name: 'description', content: 'Full-service boutique advertising agency, we guarantee strategic vision from brief to execution. Our team brings insight, passion and expertise.' },
      { property: 'og:title', content: 'Octopus Ink : Leave Your Mark' },
      { property: 'og:site_name', content: 'Octopus Ink' },
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: 'https://octopusink.ca/' },
      // { property: 'og:image', content: 'https://octopusink.ca' + require('@/assets/share-image.jpg') },
      { property: 'og:description', content: 'Full-service boutique advertising agency, we guarantee strategic vision from brief to execution. Our team brings insight, passion and expertise.' },

      { name: 'twitter:card', content: 'summary' },
      { name: 'twitter:site', content: 'https://octopusink.ca/' },
      { name: 'twitter:title', content: 'Octopus Ink : Leave Your Mark' },
      { name: 'twitter:description', content: 'Full-service boutique advertising agency, we guarantee strategic vision from brief to execution. Our team brings insight, passion and expertise.' },

      // { name: 'twitter:image:src', content: 'https://octopusink.ca' + require('@/assets/share-image.jpg') },

      { itemprop: 'name', content: 'Octopus Ink : Leave Your Mark' },
      { itemprop: 'description', content: 'Full-service boutique advertising agency, we guarantee strategic vision from brief to execution. Our team brings insight, passion and expertise.' },
      // { itemprop: 'image', content: 'https://octopusink.ca' + require('@/assets/share-image.jpg') }
    ],
    link: [
      { rel: 'canonical', href: 'https://octopusink.ca/' }
    ]
  }
}
</script>
