<template>
  <div class="social-share">
    <h4>
      Share
    </h4>
    <ul class="social-share--list">
      <li v-for="network in networks" :key="network.name">
        <a :href="network.href" target="_blank" class="social-share--link" :class="`social-share--link__${network.name.toLowerCase()}`">
          <span class="sr-only">Share on {{ network.name }}</span>
          <FontAwesomeIcon :icon="network.icon" :color="network.color"></FontAwesomeIcon>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faFacebookSquare, faLinkedin } from '@fortawesome/free-brands-svg-icons'

function hexToRgb(hex, alpha = 1) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? `rgba(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}, ${alpha})` : null;
}

export default {
  components: {
    FontAwesomeIcon
  },
  props: {
    post: {
      type: Object,
      required: true
    }
  },
  computed: {
    networks() {
      return [
        {
          name: 'Facebook',
          href: `https://www.facebook.com/sharer/sharer.php?u=${this.post.link}`,
          icon: faFacebookSquare,
          color: '#3B5998',
          color_bg: hexToRgb('#3B5998', '0.3')
        },
        {
          name: 'LinkedIn',
          icon: faLinkedin,
          href: `https://www.linkedin.com/shareArticle?mini=true&url=${ this.post.link }&title=${ this.post.title.rendered }`,
          color: '#0E76A8',
          color_bg: hexToRgb('#0E76A8', '0.3')
        }
      ]
    }
  },
  data () {
    return {
    }
  }
}
</script>
