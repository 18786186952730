import axios from 'axios'
import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

if (window.prerenderSettings) {
  axios.defaults.baseURL = window.prerenderSettings.axiosBaseURL;
} else if (process.env.NODE_ENV === 'development') {
  axios.defaults.baseURL = 'http://octopusink.octo';
}

const mapToObject = (arr) => {
  if (!Array.isArray(arr)) return arr
  if (!arr || arr.length === 0) return {}
  return arr.reduce(function(map, obj) {
    map[obj.id] = obj;1
    return map;
  }, {});
}

const getters = {
  allCategories: state => state.categories,
  thisCategory: state => slug => state.categories.find(category => category.slug === slug),
  theseCategories: state => arr => arr.map(catId => state.categories.find(category => category.id === catId)),
  allPosts: state => state.posts,
  thisPost: state => slug => Object.keys(state.posts).length ? state.posts.find(post => {
    if (!post) return
    return post.slug === slug
  }) : null,
  allAuthors: state => state.authors,
  thisAuthor: state => slug => Object.values(state.authors).find(author => author.slug === slug),
  thisAuthorById: state => id => state.authors ? state.authors[id] : null,
  thisMedia: state => name => state.media[name],
  numPosts: state => state.numPosts,
  numPages: state => state.numPages,
  myWorkSliderPos: state => state.myWorkSliderPos
}

const state = {
  error: false,
  categories: [],
  posts: [],
  authors: {},
  media: {},
  numPosts: 0,
  numPages: 1,
  myWorkSliderPos: 0
}

const actions = {
  async getCategories({commit}){
    commit('clearError')
    if (state.categories.length) return
    const response = await axios.get('/blog/wp-json/wp/v2/categories', {
      params: {
        hide_empty: true
      }
    })
    commit('setCategories', response.data)
  },
  async getPosts({commit}, query){
    commit('clearError')
    const response = await axios.get('/blog/wp-json/wp/v2/posts', {
      params: query
    }).catch(e => {
      state.error = e.response.data
    })
    commit('setNumPosts', response.headers['x-wp-total'])
    commit('setNumPages', response.headers['x-wp-totalpages'])
    commit('setPosts', response.data)
  },
  async getPost({commit}, params){
    commit('clearError')
    if (!params || getters.thisPost(state)(params.slug)) return
    const response = await axios.get('/blog/wp-json/wp/v2/posts', {
      params
    }).catch(e => {
      state.error = e.response.data
    })
    if (!response.data || response.data.length === 0) {
      state.error = 'No post found'
    }
    commit('setPost', response.data[0])
  },
  async getAuthors({commit}){
    commit('clearError')
    if (state.authors.length) return
    const response = await axios.get('/blog/wp-json/wp/v2/users')
    commit('setAuthors', response.data)
  },
  async getMedia({commit}, name){
    commit('clearError')
    if (state.media[name]) return
    let url = '/blog/wp-json/octopus/v1/media'
    const response = await axios.get(url, {
      params: {
        name
      }
    })

    if (!response.data || response.data.length === 0) return

    if (!Array.isArray(name)) {
      commit('setMedia', [name, response.data])
    } else {
      name.forEach((n) => {
        if (!response.data[n] || response.data[n].length === 0) return
        commit('setMedia', [n, response.data[n]])
      })
    }
  }
}

const mutations = {
  setCategories: (state, categories) => (state.categories = categories),
  setPosts: (state, posts) => (state.posts = posts),
  setPost: (state, post) => (state.posts.push(post)),
  setAuthors: (state, authors) => (state.authors = mapToObject(authors)),
  setMedia: (state, medium) => (state.media[medium[0]] = medium[1]),
  setNumPosts: (state, num) => (state.numPosts = Number(num)),
  setNumPages: (state, num) => (state.numPages = Number(num)),
  clearError: (state) => state.error = false,
  myWorkSliderPos: (state, pos) => state.myWorkSliderPos = pos
}


export default new Vuex.Store({
  state,
  getters,
  actions,
  mutations
})
